<template>
  <a class="btn btn-primary" :href="downloadLink" target="_blank">
    {{question.buttonLabel}}
  </a>
</template>
<script>

import { get } from 'lodash';

export default {
  props: ['question', 'path', 'responsePath'],
  computed: {
    devisId() {
      return this.getDevisId();
    },
    downloadLink() {
      return `${process.env.API_URL}/api/devis/${this.devisId}/download`;
    },
  },
  methods: {
    getDevisId() {
      return get(this.$store.state, 'survey.sections.identification.questions.exportId.response');
    },
  },
};
</script>
