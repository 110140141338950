import survey from '../../data/survey.json';

const state = {
  survey,
  surveyWithClientCode: false,
  isSurveyCompleted: false,
  devisCaisse: {},
};

export default state;
