<template>
  <div :class="`${question.answerColClass || 'col'} field`">
    <v-select
      :name="question.labelInput"
      :taggable="question.taggable"
      :options="options"
      :placeholder="question.placeholder"
      :class="question.fieldClass"
      :modelValue="response"
      @option:selected="value => setValue({ path, responsePath, value })"
      @search="onSearch"
      selectOnTab
      push-tags
    >
    <template #no-options>
      Pas de résultats
    </template>
  </v-select>
    <label v-if="question.labelInput">{{question.labelInput}}</label>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import axios from 'axios';
import { map } from 'lodash';
import VSelect from 'vue-select';

import { getOptions, getProp } from '../../utils/SurveyUtils';

export default {
  components: {
    VSelect,
  },
  props: ['question', 'path', 'responsePath'],
  data() {
    return {
      options: map(getOptions(this.question.options), label => {
        if (label.codePostal) {
          console.log('label : ', label);
          return label.codePostal + ' ' + label.ville;
        } else {
          return label;
        }
      }),
    };
  },
  computed: {
    response() {
      return getProp(this.question, this.responsePath, 'response');
    },
  },
  methods: {
    ...mapActions([
      'setValue',
    ]),
    optionsState() {
      // il faut aller chercher dans la state l'endroit ou sont stocker les options
      // ensuite il faut faire un map pour tous les parcourri

      // this.question.optionsState (value du chemin)
      // toute les cp viles = get du state survey, avec value du chemin
      const cpVilles = getProp(this.store.state, this.question.optionsState);
      return map(cpVilles, (o) => (o.codePostal + o.ville));
    },
    onSearch(search, loading, spinner) {
      if (this.question.optionsURL) {
        loading(true);
        axios.get(
          `${process.env.API_URL}/${this.question.optionsURL}/${search}`
        ).then(res => {
          this.options = map(res.data.data, d => `${d.codePostal} - ${d.ville}`);
          loading(false);
        });
      }
    },
  },
};
</script>
